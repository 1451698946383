body {
  color: @dark-gray-color;
  color: var(--dark-gray);
  font-variant-numeric: lining-nums;
}

h3, .h3,
h4, .h4,
h5, .h5,
main input,
main select {
  font-family: "Foundry Gridnik W01";
}

h3, .h3,
h4, .h4 {
  font-weight: @medium-font-weight;
}

h5, .h5 {
  font-weight: @normal-font-weight;
  text-transform: uppercase;
}

h3, .h3 {
  .fluid-property(font-size; 21; 26);
  line-height: round(30/26, 2);
  margin-top: round(30/26, 2) * 1em;
}

h4, .h4 {
  font-size: 19px;
  line-height: round(26/19, 2);
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-top: round(26/19, 2) * 1em;
}
h5, .h5 {
  .fluid-property(font-size; 16; 16);
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
.site-header .nav li,
.close {
  color: @indigo-color;
  color: var(--indigo);
}

p {
  line-height: round(30/16, 3);
  margin-top: round(30/16, 3) * 1em;
}

ul {
  margin-top: round(30/16, 3) * 1em;
}

h3, .h3,
h4, .h4,
h5, .h5,
ul, li {
  &:first-child {
    margin-top: 0;
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  small, .small {
    font-size: 65%;
  }
}
