

.site-footer {
  background-color: @indigo-color;
  background-color: var(--indigo);
  color: white;

  .vertical-padded {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  h5 {
    font-weight: @medium-font-weight;
  }

  ul, p {
    margin-top: 0.75em;
  }

  .brand-logo {
    text-align: right;
  }

  nav {
    a {
      @font-size: 14;
      @line-height: 30;
      @border-height: 1;
      font-size: unit(@font-size, px);
      line-height: round(@font-size/@line-height, 2);
      .animate-underline(unit(@border-height, px));
      // padding-bottom: unit((@line-height - @font-size) / 2 - @border-height, px);
      padding-bottom: 4px;
    }
  }

  small, div.small {
    font-family: "Foundry Gridnik W01";
    font-size: 10px;
    font-weight: @medium-font-weight;
    text-transform: uppercase;
    color: @turquoise-color;
    color: var(--turquoise);

    & a {
      &:hover, &:focus {
        color: white;
      }
    }


  }

  @media (max-width: @reflex-xs-max) {
    .brand-logo {
      text-align: center;
    }
  }
}
