.form-inline {
  display: inline-block;
}

.form-group {
  position: relative;
  display: block;
  color: @indigo-color;
  color: var(--indigo);

  .form-label, input {
    font-weight: @normal-font-weight;
  }

  font-size: 30px;

  input:not([type="checkbox"]):not([type="radio"]),
  select,
  textarea {
    width: 100%;
    font-size: 1em;
    border: none;
    color: inherit;
    background-color: transparent;
    border-bottom: 1.5px solid;
    box-sizing: border-box;

    &[required] + .form-label {
      &:after {
        content: "*";
        color: @red-color;
        color: var(--red);
        font-size: 0.75em;
        vertical-align: middle;
      }
    }

    &:focus {
      outline: 0;
    }
  }

  select {
    -webkit-appearance: none;
    border-radius: 0;
  }

  // Because we hide the file input, we need to put the border
  // to the label and remove it when there is file.
  input[type="file"] {
    & + .form-label {
      border-bottom: 1.5px solid @indigo-color;
    }

    &:valid {
      & + .form-label {
        border-bottom: none;
      }
    }
  }

  .form-label {
    width: 100%;
    cursor: pointer;
  }

  .float-label(span; @ratio: 16/30; @on-static: {
    font-family: "Foundry Gridnik W01";
    color: @dark-gray-color;
    color: var(--dark-gray);
  });

  input[type="checkbox"],
  input[type="radio"] {
    & + .form-label {
      font-size: 16px;
      color: @dark-gray-color;
      color: var(--dark-gray);
    }
  }

  textarea {
    border: 1.5px solid @light-gray-color;
    margin-top: 2em;
    padding: 0.25em 0.5em;
    font-family: "Fort";
    font-weight: @normal-font-weight;
    font-size: 21px;
    line-height: round(36/21, 2);
    color: @dark-gray-color;
    color: var(--dark-gray);

    &:placeholder-shown:not(:focus) + .form-label {
      color: @indigo-color;
      color: var(--indigo);
    }
  }

  &.multiline {
    margin-top: 0;

    & .form-label {
      display: block;
      font-family: "Foundry Gridnik W01";
      font-weight: @medium-font-weight;
      transform: none;
      font-size: 1em;
      top: 0;
      line-height: 1;
      margin-bottom: 0.5em;
    }

    & textarea {
      margin-top: 0;
    }
  }

  // option:checked { display:none; }

  &[hidden] {
    display: none;
  }
}

.checkbox, .radio {
  min-height: 20px;
  margin-top: 0;
  font-weight: 400;
  cursor: pointer;
  display: block;

  & input {
    position: absolute;
    opacity: 0;
  }

  & .form-label {
    &:before {
      content: "";
      width: round(20/16, 2) * 1em;
      height: round(20/16, 2) * 1em;
      display: inline-block;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 1rem;
      transition: all 0.2s;
    }
  }

  & input:checked {
    & + .form-label:before {
      color: @turquoise-color;
      color: var(--turquoise);
      border-color: inherit;
    }
  }
}

.checkbox {
  & .form-label {
    &:before {
      .background-svg("../images/check-off.svg", @dark-gray-color);
    }
  }

  input:focus + .form-label {
    &:before {
      .background-svg("../images/check-off.svg", @turquoise-color);
    }
  }

  input:checked + .form-label {
    &:before {
      .background-svg("../images/check-on.svg", @turquoise-color);
    }
  }
}

.radio {
  & .form-label {
    &:before {
      .background-svg("../images/radio-off.svg", @dark-gray-color);
    }
  }

  input:focus + .form-label {
    &:before {
      .background-svg("../images/radio-off.svg", @turquoise-color);
    }
  }

  input:checked + .form-label {
    &:before {
      .background-svg("../images/radio-on.svg", @turquoise-color);
    }
  }
}

.form-group, .checkbox, .radio {
  margin-top: 30px;
}

.form-group[role="group"] {
  border: none;

  .checkbox, .radio {
    line-height: 1;
    margin-top: 0;
  }

  label[id*="__label"] {
    font-family: "Foundry Gridnik W01";
    font-weight: @medium-font-weight;
    line-height: 1;
  }
}
