@import "tsi2018/variables";
@import (less) "./tsi2018.critical.css";

@font-face {
  .font-face("Fort", "Fort-Book");
}
@font-face {
  .font-face("Fort", "Fort-Bold", bold);
}

@import "tsi2018/mixins";
@import "tsi2018/reset";

@import "../node_modules/reflex-grid/less/reflex";

@import "tsi2018/typography";
@import "tsi2018/elements";
@import "tsi2018/tsi2018.user.less";
@import "tsi2018/forms";

.page-container {
  position: relative;
}

@import "tsi2018/header";

main {
  .container, .two-column [class*=col-] {
    .vertical-padded;
  }

  .col-centered {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.container {
  max-width: @container-width;
}

.banner {
  & .container {
    max-width: none;
    padding: 0;

    & img {
      display: block;
      width: 100%;
    }
  }
}

.jumbotron, .carousel {
  padding: 0;
}

.jumbotron {
  color: white;

  h1 {
    color: white;
  }

  p {
    margin-top: 1em;
  }
}

.jumbotron figure.image {
  .card;
}

.two-column {
  [class*="col-"]:not([class*="col-bleed"]) {
    // In the design, the content are visually centered in the column
    // with the help of padding. Therefore, a percentage padding is
    // required. However, the padding is calculated againsst a 1100px
    // grid, while our grid is based on a 1200px (minus the gutters).
    @designed-grid: 1110;
    @designed-viewport: 1330;
    @desired-grid: unit(@reflex-lg - @reflex-grid-spacing * 4);
    @padding: (((@designed-viewport - @desired-grid) / 2) / @designed-viewport) * 100%;

    padding-left: @padding;
    padding-right: @padding;

    .container {
      padding: 0;
      max-width: @reflex-lg/2;
    }
  }

  .grid--cover {
    & [class*="col-"] {
      &:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      .container {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  [class*="col-"].cover-image {
    padding: 0;
    vertical-align: middle;

    & .container {
      max-width: none;
    }

    & .container, & #editForm, & .SAMChunkWrapper, & p {
      height: 100%;
    }

    @media (max-width: @reflex-xs-max) {
      order: -1;
    }
  }
}

@import "tsi2018/footer";

.no-flexbox {
  .two-column {
    .grid {
      display: table;
      width: 100%;

      [class*="background-"] {
        display: table-cell;
      }
    }
  }
}

.show-grid {
  margin-bottom: 15px;

  [class*=col-] {
    @padding: @reflex-grid-spacing;
    padding-top: @padding;
    padding-bottom: @padding;
    border: 1px solid #ccc;

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: @padding;
        right: @padding;
        bottom: @padding;
        left: @padding;
        background-color: fade(#83e3ff, 50);
      }
  }
}

.debug-grid {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  .container, .grid {
    position: relative;
    height: 100%;
  }
}
