.site-header {
  .brand-logo {
    padding-top: 21px;
    padding-bottom: 21px;
    text-align: left;

    svg {
      vertical-align: middle;
      display: inline-block;
      width: auto;
    }
  }
  .navbar-toggle {
    display: none;
  }

  .nav {
    a {
      font-variant: small-caps;
      text-transform: lowercase;
      letter-spacing: 0.06em;
    }
  }

  ul, li {
    display: inline-block;
  }

  .search-box {
    visibility: hidden;
    opacity: 0;
    height: 100%;
  }

  .visible-searching {
    visibility: hidden;
    opacity: 0;
    max-width: 0;
    max-height: 0;
  }
}

#primary-nav {
  & > .nav {
    width: 100%;
    text-align: justify;
    text-align-last: justify;
  }

  & .secondary-nav {
    height: 0;
    overflow: hidden;
  }

  & li {
    vertical-align: top;

    &.isParent {
      // The down arrow.
      & > a:after {
        content: "\0203A";
        display: inline-block;
        padding: 0 0.25em;
      }
    }
  }
}

#primary-nav .secondary-nav,
.search-box {
  position: absolute;
}

@media (min-width: @reflex-sm) {
  #primary-nav {
    & > .nav {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: @reflex-xs-max) {
  .site-header {
    .navbar-header {
      & > * {
        padding-top: (8 / 320) * 100%;
        padding-bottom: (8 / 320) * 100%;
      }
    }

    .brand-logo {
      svg {
        height: 35px;
      }
    }

    #primary-nav {
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }

    .navbar-toggle {
      display: inline-block;
      line-height: 1;
    }

    .icon-menu {
      display: block;
      margin-left: auto;
    }

    .icon-close, .icon-menu {
      margin-left: auto;
    }

    .icon-close {
      display: none;
    }

    .icon-menu {
      display: block;
    }
  }
}
