@import "tsi2018/variables";

@import url("//fast.fonts.net/cssapi/f00d82a5-9538-4607-8cc7-0a32b31e8912.css");

@font-face {
  .font-face("Fort", "Fort-Book");
}
@font-face {
  .font-face("Fort", "Fort-Bold", bold);
}

:root {
  --font-size: @body-font-size;
  --indigo: @indigo-color;
  --red: @red-color;
  --soft-blue: @soft-blue-color;
  --soft-blue-tint: @soft-blue-tint;
  --turquoise: @turquoise-color;
  --light-gray: @light-gray-color;
  --light-gray-tint: @light-gray-tint;
  --medium-gray: @medium-gray-color;
  --dark-gray: @dark-gray-color;
  --vertical-padding: @vertical-padding;
  --font-family-sans-serif: @font-family-sans-serif;
  --font-family-monospace: @font-family-monospace;
}

@import "tsi2018/mixins";

@import "tsi2018.critical/reset.less";

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Fort";
  font-weight: @normal-font-weight;
  line-height: round(30/@body-font-size, 2);
}

a {
  text-decoration: none;
  color: inherit;
}

@import "tsi2018.critical/typography.less";
@import "tsi2018.critical/grid.less";

@import "tsi2018.critical/header.less";
@import "tsi2018.critical/elements.less";

.home main {
  position: relative;
}
.home main:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/images/logo.svg") no-repeat right -15px top 25vh;
  z-index: -1;
  will-change: transform;
}

.jumbotron {
  color: #fff;

  figure.image {
    width: 100%;
    position: relative;
    display: block;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    figcaption {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      width: 50%;
      max-width: @reflex-lg/2;
      padding: @reflex-grid-spacing * 2;
      text-align: left;
    }

    @media (max-width: @reflex-xs-max) {
      color: @indigo-color;
      color: var(--indigo);

      h1 {
        color: @indigo-color;
        color: var(--indigo);
      }

      figcaption {
        position: relative;
        top: 0;
        left: auto;
        right: auto;
        width: 90%;
        transform: none;
        margin-top: round(18/320, 2) * -100vw;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
      }
    }
  }

  .reversed {
    figure.image {
      figcaption {
        left: auto;
        right: 50%;
      }

      @media (max-width: @reflex-xs-max) {
        figcaption {
          right: auto;
        }
      }
    }
  }
}

[hidden] {
  display: none;
}
